<template>
  <v-card
    class="ma-0 mb-3 mt-1 px-5"
    elevation="0"
  >
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
    >
      <validation-provider
        v-slot="{ errors }"
        :name="messageName"
        :rules="{ required: true }"
      >
        <common-input-textarea
          v-model="request"
          :error-messages="errors"
          single-line
          :placeholder="placeholder"
        />
      </validation-provider>
      <div class="d-flex">
        <v-spacer />
        <common-trs-btn
          :disabled="!valid"
          type="primary"
          class="white--text mb-3 mx-2"
          @click="send"
        >
          Send
        </common-trs-btn>
      </div>
    </validation-observer>
  </v-card>
</template>

<script>
  export default {
    name: 'CreateItem',

    components: {
    },

    props: {
      placeholder: {
        default: 'Enter your comment or question...',
      },
      messageName: {
        default: 'Comment/Question',
      },
    },

    data: () => ({
      request: '',
    }),

    computed: {
    },

    watch: {
    },

    created () {
    },

    methods: {
      send () {
        this.$emit('create', { request: this.request })
        this.request = ''
        this.$refs.observer.reset()
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
